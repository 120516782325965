export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const { $stytch } = useNuxtApp()

    if ($stytch.session.getSync()) {
      try {
        await $stytch.session.authenticate({
          session_duration_minutes: useRuntimeConfig().public.SESSION_DURATION_MINUTES,
        })
      } catch (error) {
        clearNuxtData(apiPaths.lawyer())
        return navigateTo('/lawyer-login')
      }
    } else {
      clearNuxtData(apiPaths.lawyer())
      return navigateTo('/lawyer-login')
    }
  }

  const { lawyer } = await useLawyer()
  if (!lawyer.value) {
    return navigateTo('/lawyer-login')
  }

  // Allow only lawyer-specific routes
  const allowedRoutes = ['lawyer-dashboard']
  if (!allowedRoutes.includes(to.name as string)) {
    return navigateTo('/lawyer-dashboard')
  }
})
